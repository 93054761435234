// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input {

    border-radius: 0.25rem;

    --tw-bg-opacity: 1;

    background-color: rgb(243 244 246 / var(--tw-bg-opacity));

    padding: 0.25rem
}

.input:focus {

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(59 130 246 / var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.item {

    display: flex;

    min-height: 2.5rem;

    width: 100%;

    cursor: pointer;

    align-items: center;

    border-radius: 0.25rem;

    padding: 0.25rem;

    --tw-text-opacity: 1;

    color: rgb(24 24 27 / var(--tw-text-opacity))
}

.item:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(203 213 225 / var(--tw-bg-opacity))
}
.item-selected {

    --tw-bg-opacity: 1;

    background-color: rgb(148 163 184 / var(--tw-bg-opacity))
}
.item-selected:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(148 163 184 / var(--tw-bg-opacity))
}
`, "",{"version":3,"sources":["webpack://./src/pages/TodoListPage/TodoListPage.css"],"names":[],"mappings":"AACI;;IAAA,sBAC4D;;IAD5D,kBAC4D;;IAD5D,yDAC4D;;IAD5D;AAC4D;;AAD5D;;IAAA,iBAC4D;;IAD5D,sBAC4D;;IAD5D,wDAC4D;;IAD5D,kBAC4D;;IAD5D;AAC4D;;AAI5D;;IAAA,aAC+B;;IAD/B,kBAC+B;;IAD/B,WAC+B;;IAD/B,eAC+B;;IAD/B,mBAC+B;;IAD/B,sBAC+B;;IAD/B,gBAC+B;;IAD/B,oBAC+B;;IAD/B;AAC+B;;AAD/B;;IAAA,kBAC+B;;IAD/B;AAC+B;AAG/B;;IAAA,kBAAqC;;IAArC;AAAqC;AAArC;;IAAA,kBAAqC;;IAArC;AAAqC","sourcesContent":[".input{\n    @apply p-1 rounded\n    bg-gray-100 focus:bg-white focus:border-blue-500 focus:border\n}\n\n.item{\n    @apply flex min-h-10 p-1 cursor-pointer items-center w-full rounded\n    text-zinc-900 hover:bg-slate-300\n}\n.item-selected{\n    @apply bg-slate-400 hover:bg-slate-400\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
