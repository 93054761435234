// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Disable the default focus outline in Chrome */
input:focus, textarea:focus, button:focus {
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/App/App.css"],"names":[],"mappings":"AAAA,gDAAgD;AAChD;IACI,aAAa;AACjB","sourcesContent":["/* Disable the default focus outline in Chrome */\ninput:focus, textarea:focus, button:focus {\n    outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
